<template>
  <div
    ref="dragBox"
    style="position: absolute; z-index: 1000"
    :style="position ? position : { left: 0, bottom: '60px' }"
    @touchstart="touchstartHandle('dragBox', $event)"
    @touchmove.prevent.stop="touchmoveHandle('dragBox', $event)"
  >
    <slot ref="target" />
  </div>
</template>

<script>
export default {
  name: "draggable",
  props: {
    position: {
      type: Object,
    },
    limit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      coordinate: {
        client: {},
        elePosition: {},
      },
    };
  },
  methods: {
    touchstartHandle(refName, e) {
      let element = e.targetTouches[0];
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY,
      };
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft;
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop;
    },
    touchmoveHandle(refName, e) {
      const offsetX = this.limit.x ? this.limit.x : 0;
      const offsetY = this.limit.y ? this.limit.y : 0;
      let element = e.targetTouches[0];
      // 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
      let x =
        this.coordinate.elePosition.left +
        (element.clientX - this.coordinate.client.x);
      let y =
        this.coordinate.elePosition.top +
        (element.clientY - this.coordinate.client.y);
      // 限制可移动距离，不超出可视区域
      x =
        x <= 0
          ? 0
          : x >= innerWidth - offsetX - this.$refs[refName].offsetWidth
          ? innerWidth - offsetX - this.$refs[refName].offsetWidth
          : x;
      y =
        y <= 0
          ? 0
          : y >= innerHeight - offsetY - this.$refs[refName].clientHeight
          ? innerHeight - offsetY - this.$refs[refName].clientHeight
          : y;
      // 移动当前元素
      this.$refs[refName].style.left = x + "px";
      this.$refs[refName].style.top = y + "px";
      this.$refs[refName].style.bottom = null;
      this.$refs[refName].style.right = null;
    },
  },
};
</script>

<style scoped></style>
